<template>
  <div>
    <el-button size="mini" type="primary" style="margin: 0 8px" @click="handleQueryCreateNFTs">
      铸造的NFT
    </el-button>
    <el-button
      size="mini"
      type="primary"
      style="margin: 0 8px"
      @click="passVisible = true"
      :loading="passLoading"
      v-if="+achievement.status === 3"
    >
      通过
    </el-button>
    <el-button
      size="mini"
      type="danger"
      @click="visible = true"
      style="margin: 0 8px"
      :loading="rejectLoading"
      v-if="+achievement.status === 3"
    >
      驳回
    </el-button>
    <el-button
      size="mini"
      type="danger"
      @click="visible = true"
      style="margin: 8px 0"
      :loading="rejectLoading"
      v-if="+achievement.status === 1"
    >
      去除认证
    </el-button>

    <el-dialog title="驳回原因填写" :visible.sync="visible" width="30%" @close="visible = false">
      <SelectRejectMsg
        :username="achievement.user_name"
        type="achievement"
        :loading="rejectLoading"
        @confirm="handleRejectComfirm"
        @cancel="visible = false"
        :showRejectAll="achievement.status === 3"
      />
    </el-dialog>

    <el-dialog title="是否通过审核" :visible.sync="passVisible" width="30%" center>
      <span>确认通过后会使用用户申请的成就名称进行认证</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlePass">通过</el-button>
        <el-button @click="passVisible = false" type="danger">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { setAchievemrnt, rejectAchievement } from "@/api/nft.js";
import SelectRejectMsg from "@/components/SelectRejectMsg.vue";

export default {
  components: {
    SelectRejectMsg,
  },
  props: {
    achievement: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      passLoading: false,
      rejectLoading: false,
      visible: false,
      passVisible: false,
    };
  },
  methods: {
    async handlePass() {
      if (this.passLoading) {
        return;
      }

      this.passLoading = true;

      const success = await this.setAchievemrnt(1);
      if (success) {
        this.$emit("success");
        this.passVisible = false;
        this.$message.success("操作成功", 1.5);
      }

      this.passLoading = false;
    },
    async handleReject(msg, hour, type) {
      if (this.rejectLoading) {
        return;
      }

      this.rejectLoading = true;

      const success = await this.rejectAchievement(msg, hour, type);
      if (success) {
        this.$emit("success");
        this.visible = false;
        this.$message.success("操作成功", 1.5);
      }

      this.rejectLoading = false;
    },
    handleQueryCreateNFTs() {
      this.$router.push("/nft_management?creator_id=" + this.achievement.user_id);
    },

    handleRejectComfirm(msg, hour, type) {
      this.handleReject(msg, hour, type);
    },

    async setAchievemrnt(status, msg) {
      try {
        const params = {
          id: this.achievement.id,
          user_id: this.achievement.user_id,
          status,
        };

        if (msg) {
          params.msg = msg;
        }

        const { data } = await setAchievemrnt(params);
        console.log(data);
        return true;
      } catch (error) {
        const msg = error?.data?.msg;
        if (msg) {
          this.$message.error(msg);
        }
        return false;
      }
    },

    async rejectAchievement(msg, hour, type) {
      try {
        const params = {
          items: [{ id: this.achievement.id, ucid: this.achievement.user_id }],
          forbidden_hours: +hour,
          reject_all: +type,
        };

        if (msg) {
          params.msg = msg;
        }

        const { data } = await rejectAchievement(params);
        console.log(data);
        return true;
      } catch (error) {
        const msg = error?.data?.msg;
        if (msg) {
          this.$message.error(msg);
        }
        return false;
      }
    },
  },
};
</script>
