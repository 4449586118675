<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-row :gutter="20">
        <el-col :span="20" :offset="0">
          <el-row :gutter="20">
            <el-col :span="4" :offset="0">
              <el-input
                @keyup.enter.native="handleClickSearch"
                v-model="user_id"
                placeholder="请输入用户ID"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input
                @keyup.enter.native="handleClickSearch"
                v-model="mobile"
                placeholder="请输入手机号"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0"
              ><el-input
                @keyup.enter.native="handleClickSearch"
                v-model="email"
                placeholder="请输入邮箱"
                size="normal"
              ></el-input
            ></el-col>
            <el-col :span="4" :offset="0">
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :clearable="false"
              />
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" :offset="0" style="padding-top: 20px">
          <el-button type="primary" size="default" @click="handleClickSearch">
            查询
          </el-button>
          <el-button type="info" size="default" @click="handleClickReset">
            重置
          </el-button>
          <el-button
            type="danger"
            size="default"
            @click="visible = true"
            v-if="status === 3 || status == 1"
          >
            批量驳回
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-radio-group @change="handleStatusChange" v-model="status" style="margin-bottom: 10px;">
        <el-radio-button label="all">全部</el-radio-button>
        <el-radio-button :label="3">未审核</el-radio-button>
        <el-radio-button :label="1">已通过</el-radio-button>
        <el-radio-button :label="2">已拒绝</el-radio-button>
      </el-radio-group>
    </div>
    <el-table :data="list" border stripe row-key="id" @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55"
        v-if="status === 3 || status == 1"
      ></el-table-column>
      <el-table-column label="编号" prop="id"> </el-table-column>
      <el-table-column label="用户ID" prop="user_id"> </el-table-column>
      <el-table-column label="用户昵称" prop="user_name"></el-table-column>
      <el-table-column label="头像" prop="avatar">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.avatar"
            :src="scope.row.avatar"
            fit="fill"
            :lazy="true"
            :preview-src-list="[scope.row.avatar]"
            style="max-width: 80px; max-height: 80px; border-radius: 50%"
          ></el-image>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="mobile"></el-table-column>
      <el-table-column label="邮箱" prop="email"></el-table-column>
      <el-table-column label="认证类型" prop="type">
        <template slot-scope="scope">
          <span>{{ scope.row.type | typeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成就名称" prop="name"></el-table-column>
      <el-table-column label="成就辅助" prop="pics">
        <template slot-scope="scope">
          <el-image
            v-for="(pic, idx) in scope.row.pics"
            :key="idx"
            :src="pic"
            fit="fill"
            :lazy="true"
            :preview-src-list="scope.row.pics"
            style="max-width: 100px; max-height: 100px;"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status_desc"></el-table-column>
      <el-table-column label="创建时间" prop="created_at">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核时间" prop="audit_at">
        <template slot-scope="scope">
          <span>{{ scope.row.audit_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <options :achievement="scope.row" @success="handleSuccess" />
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="驳回原因填写"
      :visible.sync="visible"
      width="30%"
      @close="visible = false"
      v-if="visible"
    >
      <SelectRejectMsg
        :username="selectNames"
        type="achievement"
        :loading="rejectLoading"
        @confirm="handleClickReject"
        @cancel="visible = false"
        :showRejectAll="status === 3"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getAchievemrntList, rejectAchievement } from "@/api/nft.js";
import SelectRejectMsg from "@/components/SelectRejectMsg.vue";
import options from "./options.vue";
import dayjs from "dayjs";

export default {
  components: {
    options,
    SelectRejectMsg,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      // 查询参数
      user_id: "",
      mobile: "",
      email: "",
      status: "all",
      dateRange: "",
      selectRow: [],
      selectNames: "",
      selectItems: [],
      rejectLoading: false,
      visible: false,
    };
  },
  filters: {
    typeFilter: function(value) {
      return ["", "证书", "作品"][+value];
    },
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    const search = new URLSearchParams(window.location.search);
    const user_id = search.get("user_id");
    if (user_id) {
      this.user_id = user_id;
    }
    this.fetchAchievementList();
  },
  methods: {
    getSearchParams() {
      const params = {
        page: this.page,
        page_size: this.pageSize,
      };

      this.user_id && (params.user_id = this.user_id);
      this.mobile && (params.mobile = this.mobile);
      this.email && (params.email = this.email);
      this.status !== "all" && (params.status = +this.status);
      this.dateRange[0] &&
        ((params.start_time = this.dateRange[0]), (params.end_time = this.dateRange[1]));
      console.log(this.dateRange[0], params);

      return params;
    },
    async fetchAchievementList() {
      try {
        const params = this.getSearchParams();
        const { data } = await getAchievemrntList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        // mock
        // this.list = [
        //   {
        //     id: "id1",
        //     type: 1,
        //     name: "台北美术馆馆长",
        //     pics: [
        //       "https://static.ddpurse.com/upload/16345275227324853.jpg",
        //       "https://static.ddpurse.com/upload/16347833430671751.png",
        //     ],
        //     user_id: "123",
        //     user_name: "大脑虎",
        //     mobile: "123456789",
        //     email: "123@gmail.com",
        //     status: "pending",
        //     status_desc: "待审核",
        //     created_at: 123,
        //     audit_at: 123,
        //   },
        //   {
        //     id: "id2",
        //     type: 2,
        //     name: "清明上河图",
        //     pics: [
        //       "https://static.ddpurse.com/upload/16345275227324853.jpg",
        //       "https://static.ddpurse.com/upload/16347833430671751.png",
        //     ],
        //     user_id: "123",
        //     user_name: "大脑虎",
        //     mobile: "123456789",
        //     email: "123@gmail.com",
        //     status: "success",
        //     status_desc: "已通过",
        //     created_at: 123,
        //     audit_at: 123,
        //   },
        // ];
      }
    },
    handleSelectionChange(val) {
      this.selectRow = val;
      let arr = [];
      let items = [];
      Object.keys(val).map((key) => {
        arr.push(val[key].user_name);
        items.push({ ucid: val[key].user_id, id: val[key].id });
      });
      let nameArr = [...new Set(arr)];
      this.selectNames = nameArr.toString();
      this.selectItems = items;
      // console.log(this.selectNames, this.selectItems);
    },
    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchAchievementList();
    },
    async handleClickReject(msg, hour, type) {
      if (this.rejectLoading) {
        return;
      }

      this.rejectLoading = true;
      try {
        const params = {
          items: this.selectItems,
          forbidden_hours: +hour,
          reject_all: +type,
        };

        if (msg) {
          params.msg = msg;
        }

        const { data } = await rejectAchievement(params);
        this.visible = false;
        this.$message.success("操作成功", 1.5);
        this.rejectLoading = false;
        this.fetchAchievementList();
        return true;
      } catch (error) {
        const msg = error?.data?.msg;
        if (msg) {
          this.$message.error(msg);
        }
        return false;
      }
    },

    handleClickReset() {
      this.resetQuery();
      this.fetchAchievementList();
    },

    resetQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.user_id = "";
      this.mobile = "";
      this.email = "";
      this.status = "all";
      this.dateRange = "";
    },

    handleSuccess() {
      this.fetchAchievementList();
    },

    handleCurrentChange(page) {
      this.page = page;
      this.fetchAchievementList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchAchievementList();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchAchievementList();
    },
  },
};
</script>
